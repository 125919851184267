<template>
  <div
    class="add-team-account-form"
    :class="{
      'add-team-account-form-small': $cr.breakpoint.xs,
    }"
  >
    <v-form ref="addToParentModal">
      <v-card class="cr-modal">
        <v-card-title>
          <h2>Connect Team Account</h2>
        </v-card-title>
        <v-card-text>
          <p>Select a Customer Account</p>
          <div>
            <CustomerAccountSelector
              v-model="teamCustomerAccountFormData.customerAccountId"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-layout justify-end>
            <div class="button-cont">
              <div style="margin-right: -1.5%">
                <v-btn
                  id="close-modal-button"
                  color="grey darken"
                  outline
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn id="save-button" color="primary" @click="save">
                  Connect
                </v-btn>
              </div>
            </div>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'
import { authComputed } from '@/state/helpers'
import CustomerAccountSelector from '@/components/CustomerAccountSelector.vue'
import op from 'simple-object-path'
import { mask } from 'vue-the-mask'

export default {
  components: {
    CustomerAccountSelector,
  },
  directives: {
    mask,
  },
  props: {
    enterpriseAccountId: { type: String, default: null },
  },
  data() {
    return {
      op,
      teamCustomerAccountFormData: {
        customerAccountId: null,
      },
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    async save() {
      if (!this.teamCustomerAccountFormData.customerAccountId) {
        return
      }

      if (
        this.enterpriseAccountId ===
        this.teamCustomerAccountFormData.customerAccountId
      ) {
        return
      }

      if (this.teamCustomerAccountFormData.customerAccountId) {
        const payload = {
          parentCustomerAccountId: this.enterpriseAccountId,
          teamCustomerAccountId: this.teamCustomerAccountFormData
            .customerAccountId,
        }

        const resp = await customerAccounts.addExistingCustomerAccountToParentCustomerAccount(
          payload
        )

        if (resp.error) {
          this.$store.dispatch('app/showAlert', {
            message: 'Error adding customer account.',
          })
          return
        }
      }

      this.close()

      this.$store.dispatch('app/showAlert', {
        message: 'Customer account added.',
      })
    },
    close() {
      this.$store.dispatch('app/closeDialog')
      this.$store.dispatch('app/dialogClosedAction')
    },
  },
}
</script>
<style lang="scss" scoped>
.add-team-account-form {
  width: 520px;

  &.add-team-account-form-small {
    width: auto;
  }
}

.dialog-container {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 500px;
  padding: 4% 8% 4% 8%;
  overflow-y: hidden;
  border-radius: 5px;

  .label-cont {
    display: flex;
    justify-content: space-between;
  }

  .button-cont {
    display: flex;
    justify-content: flex-end;
  }

  label {
    margin: 0;
    font-size: 20px;
    line-height: 1.42857;
  }

  .close {
    float: right;
    padding: 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: $gray-base;
    text-shadow: 0 1px 0 $white;
    cursor: pointer;
    background: transparent;
    border: 0;
    opacity: 0.2;
  }

  .close:hover {
    opacity: 0.4;
  }
}

.description-input {
  height: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: $blue-light;
}

.delete-target {
  color: $white;
  background-color: $red !important;
}
</style>
